<template>
  <tr>
    <td>{{ item.name }}</td>
    <td style="width: 120px" class="p-2">
      <v-text-field disabled hide-details="auto" v-model="item.price" dense>
        <template #append>
          <v-icon small class="mt-1">mdi-currency-try</v-icon>
        </template>
      </v-text-field>
    </td>
    <td style="width: 120px" class="p-2">
      <v-text-field hide-details="auto" v-model.number="discountedPrice" dense>
        <template #append>
          <v-icon small class="mt-1">mdi-currency-try</v-icon>
        </template>
      </v-text-field>
    </td>
    <td style="width: 120px" class="p-2">
      <v-text-field
        hide-details="auto"
        v-model.number="discountedPercent"
        dense
      >
        <template #append>
          <v-icon small class="mt-1">mdi-percent</v-icon>
        </template>
      </v-text-field>
    </td>
  </tr>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    percentToDecimalConvert(discount_percent, price = this.item.price) {
      return price - (price * discount_percent) / 100;
    },
    decimalToPercentConvert(discounted_price, price = this.item.price) {
      return ((price - discounted_price) / price) * 100;
    },
  },
  watch: {
    discountedPrice(value) {
      if (value === null || value === undefined || value === "") {
        this.discountedPrice = 0;
        this.discountedPercent = 100;
      }
    },
  },
  computed: {
    discountedPercent: {
      get() {
        return this.decimalToPercentConvert(this.item.discounted_price);
      },
      set(value) {
        this.item.discounted_price = this.percentToDecimalConvert(value);
        this.$emit("change", this.item);
      },
    },
    discountedPrice: {
      get() {
        return this.item.discounted_price;
      },
      set(value) {
        this.item.discounted_price = value;
        this.$emit("change", this.item);
      },
    },
  },
});
</script>
