<template>
  <fragment>
    <v-simple-table class="border" height="240" fixed-header dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Ürün İsimi</th>
            <th class="text-left">Liste Fiyatı</th>
            <th class="text-left">İndirimli Fiyat</th>
            <th class="text-left">İndirim Oranı</th>
          </tr>
        </thead>
        <tbody>
          <TrainingItem
            @change="handleChange"
            v-for="item in contents"
            :item="item"
            :key="`${selectedPackageId}-${item.id}-${forceRender}`"
          />
        </tbody>
      </template>
    </v-simple-table>

    <div class="training-table__table-foot">
      <span>Toplam</span>
      <v-spacer />
      <span>
        <b>{{ this.contents ? formatPrice(totalPrice) : null }}</b>
      </span>
    </div>
  </fragment>
</template>

<script>
import { defineComponent } from "vue";
import TrainingItem from "./TrainingItem.vue";
import { formatPrice } from "@/core/helper.js";

export default defineComponent({
  props: {
    contents: {
      type: Array,
      required: true,
    },
    selectedPackageId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      forceRender: 0,
    };
  },
  emits: ["update:contents"],
  methods: {
    formatPrice,
    handleChange(training) {
      const contentClone = [...this.contents];
      const index = contentClone.findIndex((item) => item.id === training.id);
      contentClone.splice(index, 1, training);
      this.$emit("update:contents", contentClone);
    },
  },
  mounted() {
    this.$root.$on("forceRender", () => {
      this.forceRender++;
    });
  },
  computed: {
    totalPrice() {
      return this.contents.reduce((acc, curr) => {
        acc += curr.discounted_price;
        return acc;
      }, 0);
    },
  },
  watch: {
    //Total price her değiştiğinde event bus yardımıyla diğer componentler ile haberleşmesini sağladım.
    totalPrice(value) {
      this.$root.$emit("update:totalPrice", value);
    },
  },
  components: { TrainingItem },
});
</script>

<style>
.training-table__table-foot {
  display: flex;
  align-items: center;
  padding-top: 12px;
  font-size: 15px;
  width: 100%;
}
</style>
