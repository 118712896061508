import { render, staticRenderFns } from "./CalculatedLastPrices.vue?vue&type=template&id=34538792"
import script from "./CalculatedLastPrices.vue?vue&type=script&lang=js"
export * from "./CalculatedLastPrices.vue?vue&type=script&lang=js"
import style0 from "./CalculatedLastPrices.vue?vue&type=style&index=0&id=34538792&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports