<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-autocomplete
          hide-details
          :items="days"
          :outlined="outlined"
          :label="label ? label + ' (Gün)' : 'Gün'"
          :rules="[(v) => !!v || 'Doğum tarihi alanı boş bırakılamaz']"
          :required="required"
          v-model="day"
          @input="save"
          :error-messages="errors"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          hide-details
          class="ml-1"
          :items="months"
          :required="required"
          :outlined="outlined"
          :rules="[(v) => !!v || 'Doğum tarihi alanı boş bırakılamaz']"
          :label="label ? label + ' (Ay)' : 'Ay'"
          v-model="month"
          @input="save"
          :error-messages="errors"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          hide-details
          class="ml-1"
          :required="required"
          :items="years"
          :rules="[(v) => !!v || 'Doğum tarihi alanı boş bırakılamaz']"
          :outlined="outlined"
          :label="label ? label + ' (Yıl)' : 'Yıl'"
          v-model="year"
          @input="save"
          :error-messages="errors"
        />
      </v-col>
    </v-row>
    <div class="text-danger mt-1" v-if="errors">
      {{ errors }}
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    isValidForm: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    selectedToday: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      day: null,
      month: null,
      year: null,
      errors: null,
    };
  },
  created() {
    if (this.selectedToday) {
      const today = new Date().toISOString().slice(0, 10);
      this.day = today.slice(8, 10);
      this.month = today.slice(5, 7);
      this.year = today.slice(0, 4);
      this.$emit("update:value", today);
    }

    if (this.value) {
      const [year, month, day] = this.value.split("-");
      this.day = day;
      this.month = month;
      this.year = year;
    }
  },
  methods: {
    save() {
      if (!this.day || !this.month || !this.year) return;
      // this.$refs.menu.save(this.selectedDate);

      const date = moment(
        `${this.year}-${this.month}-${this.day}`,
        "YYYY-MM-DD"
      );

      if (date.isAfter(moment())) {
        this.errors = "Bugünden sonraki bir tarih giremezsiniz";
        return;
      }

      if (date.isValid()) {
        this.$emit("update:value", `${this.year}-${this.month}-${this.day}`);
        this.errors = null;
        this.isValidForm = true;
        return;
      }

      this.errors = "Geçersiz bir tarih girdiniz!";
    },
  },
  computed: {
    days() {
      return Array.from({ length: 31 }, (_, i) =>
        String(i + 1).padStart(2, 0)
      ).map((d) => d.toString());
    },
    months() {
      return Array.from({ length: 12 }, (_, i) =>
        String(i + 1).padStart(2, 0)
      ).map((m) => m.toString());
    },
    years() {
      const currentYear = new Date().getFullYear();
      return Array.from({ length: 100 }, (_, i) => currentYear - i).map((y) =>
        y.toString()
      );
    },
    formattedDate() {
      return `${this.year}-${this.month}-${this.day}`;
    },
  },
  watch: {
    formattedDate(val) {
      this.$emit("update:value", val);
    },
  },
};
</script>
