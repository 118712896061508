<template>
  <v-row dense>
    <v-col cols="12" md="12">
      <v-chip-group
        active-class="primary"
        column
        v-model="third_base_class_choice"
        @change="$emit('update:third_base_class_choice', $event)"
      >
        <v-chip v-for="c in thirdClasses" :value="c.value" :key="c.value">
          {{ c.translate }}
        </v-chip>
      </v-chip-group>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ThirdBaseClass",
  props: {
    third_base_class_choice: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      thirdClasses: [],
    };
  },
  mounted() {
    this.$apiService
      .get("/enums", {
        params: {
          enum: "third_base_classes_enum",
        },
      })
      .then((response) => {
        this.thirdClasses = response.data;
      });
  },
});
</script>
