<template>
  <div
    class="d-flex justify-content-start align-aligns-center"
    :style="{ gap: $vuetify.breakpoint.xlOnly ? '10px' : '5px' }"
    style="position: relative"
    v-if="isActionsAvailable"
  >
    <fragment v-if="!lead.user_id">
      <v-btn
        color="warning"
        x-small
        :to="{ name: 'lead-match-list', query: { active: lead.id } }"
      >
        Eşleştir
      </v-btn>
    </fragment>
    <fragment v-else-if="lead.user_id && !lead.agent_id">
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-role="{
              roles: ['lead_manager', 'superadmin', 'siliconmade_admin'],
            }"
            color="success"
            v-on="on"
            v-bind="attrs"
            x-small
          >
            Agent Ata</v-btn
          >
        </template>
        <v-list>
          <v-list-item
            @click="editLeadAgent(agent.id)"
            v-for="agent in Agents"
            :key="agent.id"
          >
            <v-list-item-title>{{ agent.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </fragment>
    <fragment v-else>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-role="{
              roles: ['lead_manager', 'superadmin', 'siliconmade_admin'],
            }"
            color="success"
            v-on="on"
            v-bind="attrs"
            x-small
          >
            <v-icon small>mdi-account-convert</v-icon></v-btn
          >
        </template>
        <v-list>
          <v-list-item
            @click="editLeadAgent(agent.id)"
            v-for="agent in Agents"
            :key="agent.id"
          >
            <v-list-item-title>{{ agent.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="isAgentGokhanAndStatusNotReached"
            color="success"
            v-on="on"
            v-bind="attrs"
            x-small
          >
            Agent Değiştir</v-btn
          >
        </template>
        <v-list>
          <v-list-item
            @click="editLeadAgent(agent.id)"
            v-for="agent in Agents"
            :key="agent.id"
          >
            <v-list-item-title>{{ agent.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        color="success"
        x-small
        v-show="isPlayButtonAvailable(lead.status)"
        @click="editLeadStatus(lead.id, 2)"
      >
        Başla
      </v-btn>
      <v-btn
        color="error"
        x-small
        dark
        v-show="!isPlayButtonAvailable(lead.status)"
        @click="showCancelLeadModal(lead.id)"
      >
        X
      </v-btn>
      <v-btn
        color="error"
        x-small
        v-show="!isPlayButtonAvailable(lead.status)"
        @click="unreach_lead(lead.id)"
      >
        U
      </v-btn>

      <v-btn
        color="success"
        x-small
        dark
        v-show="isInNotReached(lead.status)"
        @click="reach_lead(lead.id)"
      >
        Ulaşıldı
      </v-btn>

      <Note
        note_type="call_record"
        :noteable_id="lead.id"
        :name="lead.name"
        :showListOfNotes="!fromLeadShowPage"
        :noteable_type="'App\\Models\\Lead'"
      >
        <v-badge
          color="blue-grey"
          overlap
          v-show="!isPlayButtonAvailable(lead.status)"
          :content="lead.notes.length"
          :value="lead.notes.length > 0"
        >
          <v-btn
            v-show="!isPlayButtonAvailable(lead.status)"
            x-small
            color="primary"
          >
            Görüşme
          </v-btn>
        </v-badge>
      </Note>
      <a
        v-show="
          !isPlayButtonAvailable(lead.status) && !isInNotReached(lead.status)
        "
        :href="createWhatsappLink(lead)"
        target="_blank"
      >
        <v-btn color="primary" x-small> W </v-btn>
      </a>
      <OfferForm :lead_id="lead.id" :lead="lead">
        <v-btn
          v-show="
            !isPlayButtonAvailable(lead.status) && !isInNotReached(lead.status)
          "
          color="success"
          x-small
          v-permission="{
            permissions: ['offers-create'],
          }"
        >
          Teklif
        </v-btn>
      </OfferForm>
    </fragment>
    <div v-if="fromLeadShowPage">
      <router-link
        :to="{
          name: 'lead-edit',
          params: { id: lead?.id },
          query: { editable: true },
        }"
      >
        <v-btn
          color="warning"
          x-small
          dark
          v-permission="{
            permissions: ['leads-update'],
          }"
        >
          Düzenle
        </v-btn>
      </router-link>
    </div>
  </div>
  <div
    class="d-flex justify-content-start align-aligns-center"
    :style="{ gap: $vuetify.breakpoint.xlOnly ? '10px' : '5px' }"
    style="position: relative"
    v-else
  >
    <v-btn
      color="warning"
      x-small
      v-show="isCanceled(lead.status)"
      @click="editLeadStatus(lead.id, 2)"
      id="canceled-button"
    >
      Yeniden Başla
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  UPDATE_LEAD_STATUS,
  NOT_REACHED,
  REACHED,
  UPDATE_LEAD_AGENT,
} from "@/core/services/store/leads.module";

import Note from "@/view/content/notes/Note.vue";
import OfferForm from "@/view/content/offers/OfferForm.vue";

export default {
  name: "SM-Agent-Action-Buttons",
  components: {
    Note,
    OfferForm,
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
    selectedLeads: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fromLeadShowPage: this.$route.name === "lead-show" ? true : false,
    };
  },
  methods: {
    ...mapActions([
      UPDATE_LEAD_STATUS,
      NOT_REACHED,
      REACHED,
      UPDATE_LEAD_AGENT,
    ]),
    isPlayButtonAvailable(status) {
      const playButtonAvailableCodes = [6];
      return playButtonAvailableCodes.includes(status);
    },
    isCanceled(status) {
      const canceledCodes = [12];
      return canceledCodes.includes(status);
    },
    isInNotReached(status) {
      const notReachedCodes = [7, 8, 9];
      return notReachedCodes.includes(status);
    },
    async editLeadAgent(agent_id, fromLeadShowPage = this.fromLeadShowPage) {
      try {
        if (this.selectedLeads.length > 0) {
          this.selectedLeads.forEach((lead) => {
            if (lead.agent_id !== agent_id) {
              this.updateLeadAgent({
                id: lead.id,
                agent_id: agent_id,
                fromLeadShowPage: fromLeadShowPage,
              });
            }
          });
          this.$emit("leadListUpdated");
          this.$emit("update:selectedLeads", []);
          this.$toast.success("Toplu Agent atama işlemi gerçekleşti.", {
            position: "bottom-right",
            duration: 2000,
          });
        } else {
          await this.updateLeadAgent({
            id: this.lead.id,
            agent_id: agent_id,
            fromLeadShowPage: fromLeadShowPage,
          });
          this.$emit("leadListUpdated");
          this.$toast.success("Agent atama işlemi gerçekleşti.", {
            position: "bottom-right",
            duration: 2000,
          });
        }
      } catch (error) {
        this.$toast.error("Atama işlemi gerçekleştirilirken bir hata oluştu", {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    async unreach_lead(leadId) {
      try {
        await this.notReached({
          id: leadId,
          fromLeadShowPage: this.fromLeadShowPage,
        });
        this.$emit("leadListUpdated");
        this.$toast.success("Lead ulaşılamadı olarak işaretlendi", {
          position: "bottom-right",
          duration: 2000,
        });
      } catch (e) {
        this.$toast.error(e[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    async reach_lead(leadId) {
      try {
        await this.reached({
          id: leadId,
          fromLeadShowPage: this.fromLeadShowPage,
        });
        this.$emit("leadListUpdated");
        this.$toast.success("Lead bir önceki durumuna geri döndürüldü.", {
          position: "bottom-right",
          duration: 2000,
        });
      } catch (e) {
        this.$toast.error(e[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    showCancelLeadModal() {
      const params = {
        title:
          '"' + this.lead.name + '"' + " satılamadı olarak işaretlenecektir.",
        text: "Seçili lead'i 'SATILAMADI' olarak işaretlemek istediğinize emin misiniz?",
        leadId: this.lead.id,
        cancelLead: true,
      };
      this.$approvalModal.show(params);
    },
    async editLeadStatus(id, status, fromLeadShowPage = this.fromLeadShowPage) {
      try {
        await this.updateLeadStatus({ id, status, fromLeadShowPage });
        this.$emit("leadListUpdated");
        this.$toast.success("Durum güncellendi", {
          position: "bottom-right",
          duration: 2000,
        });
      } catch (error) {
        this.$toast.error(error[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    createWhatsappLink(lead) {
      const { phone, first_name } = lead;
      if (!phone) return;
      const message = `Merhaba ${first_name},`;
      const link = `https://api.whatsapp.com/send?phone=${phone}&text=${message}`;
      return link;
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    Agents() {
      return JSON.parse(localStorage.getItem("agents")).filter(
        (agent) => agent.id !== this.lead.agent_id
      );
    },
    isActionsAvailable() {
      const notAvailableStatusCodes = [10, 12];
      return !notAvailableStatusCodes.includes(this.lead.status);
    },
    isAgentGokhanAndStatusNotReached() {
      return this.currentUser.name === "Gökhan Güven" && this.lead.status === 7;
    },
  },
};
</script>
