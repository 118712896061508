<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-chip-group
          active-class="primary"
          column
          v-model="payment_method"
          @change="$emit('update:payment_method', $event)"
        >
          <v-chip
            v-for="method in payment_methods"
            :value="method.value"
            :key="method.value"
          >
            {{ method.translate }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import AxiosInstance from "@/core/services/axios.service";

export default defineComponent({
  props: {
    payment_method: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      payment_methods: [],
      loading: false,
    };
  },
  beforeCreate() {
    AxiosInstance.get("/enums", {
      params: {
        enum: "payment_methods_enum",
      },
    })
      .then((response) => {
        this.payment_methods = response.data;
      })
      .catch(() => {
        this.$toast.error("Ödeme Yöntemleri alınamadı.", {
          duration: 2000,
          position: "bottom-right",
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
});
</script>

<style></style>
