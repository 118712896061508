<template>
  <div class="step">
    <div class="step--stepper">
      <span>{{ step }}</span>
    </div>
    <div>
      <div class="step--title">
        <h3>{{ title }}</h3>
        <slot name="actions" />
      </div>
      <v-divider class="mb-3" />
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
});
</script>

<style>
.step {
  position: relative;
  padding: 6px 0px;
  margin-bottom: 20px;
}

.step .step--stepper {
  position: absolute;
  top: -0.5rem;
  left: -4.5rem;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: var(--v-primary-base);
}

.step .step--title {
  display: flex;
  height: 1.6rem;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 576px) {
  .step .step--stepper {
    left: -3.7rem;
  }
}
</style>
