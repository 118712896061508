<template>
  <v-simple-table class="border mb-5">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Taksit</th>
          <th class="v-data-table__divider text-left"></th>
          <th class="text-left">Taksit Tutarı</th>
          <th class="v-data-table__divider text-left"></th>
          <th class="text-left">Ödeme Tarihi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="index in createdPlan.id" :key="index">
          <td class="text-left">{{ index }}</td>
          <td class="v-data-table__divider text-left"></td>
          <td class="text-left">
            {{ formatPrice(createdPlan.price) }}
          </td>
          <td class="v-data-table__divider text-left"></td>
          <td class="text-left">
            {{ dueDates[index - 1] }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { formatPrice } from "@/core/helper.js";

export default {
  name: "PaymentsTable",
  props: {
    createdPlan: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatPrice,
    createInstallmentDueDate(date, index) {
      let installmentDate = this.$moment(date);
      installmentDate = installmentDate
        .add(index, "months")
        .date(
          Math.min(
            this.$moment(installmentDate).date(),
            installmentDate.daysInMonth()
          )
        );
      return installmentDate.format("DD/MM/YYYY");
    },
  },
  computed: {
    dueDates() {
      const dueDates = [];
      dueDates.push(
        this.$moment(this.createdPlan.installmentStartDate).format("DD/MM/YYYY")
      );
      for (let i = 0; i <= this.createdPlan.id - 1; i++) {
        dueDates.push(
          this.createInstallmentDueDate(
            this.createdPlan.remainingInstallmentStartDate,
            i
          )
        );
      }
      return dueDates;
    },
  },
};
</script>
