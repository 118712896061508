<template>
  <v-btn
    x-small
    color="success"
    class="white--text mt-1"
    @click="sendRegisterForm"
  >
    Kayıt Formunu Tekrar Gönder
  </v-btn>
</template>

<script>
export default {
  props: {
    lead_id: Number,
  },
  methods: {
    sendRegisterForm() {
      this.$apiService
        .post(`leads/${this.lead_id}/sendFormAgain`)
        .then(() => {
          this.$toast.success("Kayıt formu başarıyla gönderildi.", {
            position: "bottom-right",
            duration: 2000,
          });
        })
        .catch(() => {
          this.$toast.error("Kayıt formu gönderilemedi.", {
            position: "bottom-right",
            duration: 2000,
          });
        });
    },
  },
};
</script>
