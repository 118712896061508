<template>
  <v-btn
    x-small
    color="success"
    class="white--text mt-1"
    @click="sendVerifyEmail"
  >
    Doğrulama E-Postasını Gönder
  </v-btn>
</template>

<script>
export default {
  props: {
    lead_id: Number,
  },
  methods: {
    sendVerifyEmail() {
      this.$apiService
        .post(`leads/${this.lead_id}/sendVerifyEmailAgain`)
        .then(() => {
          this.$toast.success("Doğrulama e-postası başarıyla gönderildi.", {
            position: "bottom-right",
            duration: 2000,
          });
        })
        .catch(() => {
          this.$toast.error("Doğrulama e-postası gönderilemedi.", {
            position: "bottom-right",
            duration: 2000,
          });
        });
    },
  },
};
</script>
