<template>
  <div v-if="!loading">
    <div class="d-flex justify-content-between mb-4">
      <v-chip
        v-for="(pack, index) in packages"
        :key="pack.id"
        :color="
          selectedPackage?.id === pack?.id ? colors[index] : 'grey darken-1'
        "
        class="ma-1"
        text-color="white"
        @click="getSelectedPackage(pack)"
      >
        {{ pack.name }}
      </v-chip>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { GET_ACTIVE_PACKAGES } from "@/core/services/store/definitions/packages.module";

export default defineComponent({
  name: "PackageCards",
  props: {
    selectedPackage: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      colors: [
        "green darken-2",
        "pink darken-1",
        "orange darken-1",
        "blue darken-1",
        "purple darken-1",
        "teal darken-1",
        "indigo darken-1",
        "brown darken-1",
        "grey darken-1",
        "red darken-1",
      ],
      selectedPackage: null,
      loading: true,
      packages: [],
    };
  },
  created() {
    this.getActivePackages()
      .then((res) => {
        this.packages = Object.assign([], res);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions([GET_ACTIVE_PACKAGES]),
    getSelectedPackage(pack) {
      this.selectedPackage = pack;
      this.$emit("update:selectedPackage", this.selectedPackage);
    },
  },
});
</script>
