<template>
  <div>
    <v-simple-table class="border mb-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Ders İsimi</th>
            <th class="v-data-table__divider text-left"></th>
            <th class="text-left">Liste Fiyatı</th>
            <th class="v-data-table__divider text-left"></th>
            <th class="text-left">İndirimli Son Fiyat</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in createdPlan.educations" :key="item.id">
            <td class="text-left">{{ index + 1 }}-{{ item.name }}</td>
            <td class="v-data-table__divider text-left"></td>
            <td class="text-left">{{ formatPrice(item.price) }}</td>
            <td class="v-data-table__divider text-left"></td>
            <td class="text-left">
              {{ formatPrice(item.newPrice) }}
            </td>
            <td class="v-data-table__divider text-left"></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <OfferSummary
      :remainingInstallmentStartDate="remainingInstallmentStartDate"
      :installmentStartDate="installmentStartDate"
      :createdPlan="createdPlan"
    />
  </div>
</template>

<script>
import OfferSummary from "./OfferSummary.vue";
import { formatPrice } from "@/core/helper.js";
export default {
  name: "CalculatedLastPrices",
  components: {
    OfferSummary,
  },
  props: {
    educations: {
      type: Array,
      required: true,
    },
    installments: {
      type: Array,
      required: true,
    },
    installmentStartDate: {
      type: String,
      default: new Date(Date.now()).toISOString().substring(0, 10),
    },
    remainingInstallmentStartDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      createdPlan: [],
      educationPrices: [],
    };
  },
  created() {
    this.calculateLastPrices();
  },
  watch: {
    educations: {
      handler() {
        this.calculateLastPrices();
      },
      deep: true,
    },
    installments: {
      handler() {
        this.calculateLastPrices();
      },
      deep: true,
    },
    createdPlan: {
      handler() {
        if (!this.$route.params.id) {
          this.$emit("update:createdPlan", this.createdPlan);
        }
      },
      deep: true,
    },
  },
  methods: {
    formatPrice,
    calculateLastPrices() {
      // const selectedPackageTotalPrice = this.educations.reduce(
      //   (total, education) => total + education.price,
      //   0
      // );
      const createdPlan = {
        ...this.installments,
        installmentStartDate:
          this.installments.installmentStartDate ?? this.installmentStartDate,
        remainingInstallmentStartDate:
          this.installments.remainingInstallmentStartDate ??
          this.createRemainingInstallmentStartDate(this.installmentStartDate),
        educations: this.educations.map((education, index) => {
          // let isLast = index === this.educations.length - 1 ? true : false;
          // const temp = {};
          // temp.newPrice = this.calculatePerInstallmentForEducation(
          //   selectedPackageTotalPrice,
          //   this.installments.id,
          //   this.installments.price,
          //   education.price,
          //   isLast
          // );
          // return {
          //   ...education,
          //   ...temp,
          // };
          if (index === 0 && this.installments.difference > 0) {
            education.newPrice =
              education.discounted_price + this.installments.difference;
          } else {
            education.newPrice = education.discounted_price;
          }
          return education;
        }),
      };
      this.createdPlan = createdPlan;
    },
    createRemainingInstallmentStartDate(startDate = null) {
      const first_date =
        startDate ?? new Date(Date.now()).toISOString().substring(0, 10);
      const date = new Date(first_date);
      const result = date.setMonth(date.getMonth() + 1);
      return new Date(result).toISOString().substring(0, 10);
    },
    // calculatePerInstallmentForEducation(
    //   totalPrice,
    //   installmentCount,
    //   installmentPrice,
    //   educationPrice,
    //   isLast
    // ) {
    //   if (!isLast) {
    //     const priceRatio = Math.ceil(
    //       installmentPrice * (educationPrice / totalPrice)
    //     );
    //     this.educationPrices.push(priceRatio);
    //     return priceRatio * installmentCount;
    //   } else {
    //     const lastPrice =
    //       installmentPrice -
    //       this.educationPrices.reduce((total, price) => total + price, 0);
    //     this.educationPrices = [];
    //     return lastPrice * installmentCount;
    //   }
    // },
  },
};
</script>

<style>
.installment-table__table-foot {
  display: flex;
  align-items: center;
  padding-top: 12px;
  font-size: 15px;
  width: 100%;
}
</style>
