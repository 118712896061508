<template>
  <v-card flat class="pa-5">
    <div class="d-flex justify-content-between">
      <h2 v-if="!fromShowLeadPage">Notlar ve Kayıtlar</h2>
      <v-spacer></v-spacer>
      <div class="d-flex align-items-center">
        <v-icon
          color="error cursor-pointer"
          class="mr-2"
          :disabled="!hasNote"
          @click="showDeleteNoteModal"
        >
          mdi-delete
        </v-icon>
        <v-text-field
          v-model="search"
          label="Ara"
          class="mx-4 mt-0 pt-0"
          hide-details="auto"
          single-line
          append-icon="search"
        ></v-text-field>
      </div>
    </div>
    <v-card-title class="d-flex justify-content-end">
      <Note
        :noteable_type="noteable_type"
        :noteable_id="noteable_id"
        note_type="call_record"
        v-if="!fromShowLeadPage"
        :showListOfNotes="false"
      >
        <v-btn class="mr-3" elevation="0"> Görüşme Kaydı Ekle </v-btn>
      </Note>
      <Note
        :noteable_type="noteable_type"
        :noteable_id="noteable_id"
        :showListOfNotes="false"
        v-if="!fromShowLeadPage"
        note_type="note"
      >
        <v-btn elevation="0" color="primary"> Not Ekle </v-btn>
      </Note>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="notes"
        :search="search"
        :header-props="{ sortIcon: null }"
        v-model="selectedNote"
        :loading="loading"
        :items-per-page="5"
        show-select
        single-select
      >
        <template #item.note_type="{ item }">
          <span> {{ convertNoteType(item.note_type) }}</span>
        </template>
        <template #item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <Note
                :noteable_type="noteable_type"
                :note_type="item.note_type"
                :noteable_id="noteable_id || Number($route.params.id)"
                :note_id="item.id"
                :showListOfNotes="false"
                :clearData="false"
              >
                <v-icon
                  class="text-primary mr-2 cursor-pointer"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </Note>
            </template>
            <span>Düzenle</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { DELETE_NOTE, FETCH_NOTES } from "@/core/services/store/notes.module";
import { defineComponent } from "vue";

export default defineComponent({
  components: { Note: () => import("./Note.vue") },
  props: {
    noteable_id: {
      type: Number,
      required: true,
    },
    noteable_type: {
      type: String,
      required: true,
    },
    fromShowLeadPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          value: "actions",
          align: "center",
          width: "100px",
          sortable: false,
        },
        { text: "Tip", value: "note_type" },
        { text: "Not", value: "content" },
        { text: "Tarih", value: "created_at" },
      ],
      search: "",
      selectedNote: [],
      dialog: false,
      loading: true,
    };
  },
  mounted() {
    this.getLeadNotes();
  },
  methods: {
    ...mapActions([DELETE_NOTE, FETCH_NOTES]),
    convertNoteType(type) {
      return type === "call_record" ? "Görüşme Kaydı" : "Not";
    },
    async acceptDelete() {
      try {
        await this.deleteNote(this.selectedNote[0]);
        this.$toast.success("Not silindi", {
          position: "bottom-right",
          duration: 2000,
        });
        this.selectedNote = [];
      } catch (error) {
        this.$toast.error("Not silinirken bir hata oluştu", {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    getLeadNotes() {
      this.fetchNotes({
        noteable_type: this.noteable_type,
        noteable_id: this.noteable_id,
      })
        .then((response) => {
          this.notes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showDeleteNoteModal() {
      const params = {
        title: "Seçtiğiniz not silinecek",
        text: "Seçili notu silmek istediğinize emin misiniz?",
        onConfirm: () => {
          return this.acceptDelete();
        },
      };
      this.$approvalModal.show(params);
    },
  },
  computed: {
    ...mapGetters(["notes"]),
    hasNote() {
      return this.selectedNote.length === 1;
    },
  },
});
</script>

<style></style>
