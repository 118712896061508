<template>
  <fragment v-if="!loading">
    <v-row justify="space-between">
      <v-col cols="5">
        <v-btn
          fab
          dark
          elevation="20"
          v-for="(i, index) in installmentsChoices"
          class="ml-2"
          :key="index"
          small
          @click="selectedInstallmentChoice = i"
          :color="selectedInstallmentChoice === i ? 'indigo' : 'grey'"
        >
          <h2 class="mt-2" style="position: absolute">{{ i }}</h2>
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-switch
          v-model="interestApplied"
          label="Vade Farkı Al"
          color="primary"
        />
      </v-col>
    </v-row>
    <v-simple-table class="border" :height="tableHeight" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center"></th>
            <th class="text-center">Taksit Sayısı</th>
            <th class="text-center">Aylık Ödeme Tutarı</th>
            <th class="text-center">Toplam Tutar</th>
            <th class="text-center">Vade Farkı</th>
            <th class="text-center">Toplam Vadeli Tutar</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="installment in createdPlan"
            :key="installment.installment_id"
          >
            <td>
              <v-checkbox
                v-model="selectedInstallment"
                :value="installment.id"
                hide-details="auto"
                class="m-0 p-2"
                on-icon="mdi-checkbox-marked-circle-outline"
                off-icon="mdi-checkbox-blank-circle-outline"
              />
            </td>
            <td class="text-center">{{ installment.id }}</td>
            <td class="text-center">
              {{ formatPrice(installment.price) }}
            </td>
            <td class="text-center">
              {{ formatPrice(totalPrice) }}
            </td>
            <td class="text-center">
              {{ formatPrice(installment.interest) }}
            </td>
            <td class="text-center">
              {{ formatPrice(installment.totalPrice) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </fragment>
</template>

<script>
import { defineComponent } from "vue";
import CustomDatePicker from "@/view/content/components/CustomDatePicker.vue";
import { formatPrice } from "@/core/helper.js";

export default defineComponent({
  name: "Installments",
  components: {
    CustomDatePicker,
  },
  props: {
    installmentCount: {
      type: Number,
      default: null,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
    cancelInterestRate: {
      type: Boolean,
      default: false,
    },
    installmentStartDate: {
      type: String,
      default: new Date(Date.now()).toISOString().substring(0, 10),
    },
    remainingInstallmentStartDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      plan: [],
      selectedInstallment: this.installmentCount,
      interestApplied: !this.cancelInterestRate,
      interestRates: [],
      loading: true,
      installmentsChoices: [12, 18, 24],
      selectedInstallmentChoice: 12,
      newTotalPrice: null,
    };
  },
  beforeMount() {
    this.getInterestRates()
      .then((response) => {
        this.interestRates = response.reduce((acc, interest) => {
          acc[interest.id] = interest.ratio;
          return acc;
        }, {});
      })
      .finally(() => {
        this.loading = false;
      });
  },
  mounted() {
    this.$root.$on("update:totalPrice", (totalPrice) => {
      this.totalPrice = totalPrice;
      this.pushInstallmentsToOfferForm(this.selectedInstallment);
    });
    // this.$root.$on("update:startDate", (startDate) => {
    //   this.startDate = startDate;
    // });
    if (this.selectedInstallment)
      this.pushInstallmentsToOfferForm(this.selectedInstallment);
  },
  methods: {
    formatPrice,
    calculateInstallmentPrice(amount) {
      return Math.ceil(
        this.calculateTotalPriceWithInterestRate(amount) / amount
      );
    },
    calculateTotalPriceWithInterestRate(amount, installmentPrice = null) {
      let interestRatio = this.interestApplied ? this.interestRates[amount] : 0;
      if (installmentPrice) {
        return installmentPrice * amount;
      }
      return this.totalPrice + this.totalPrice * interestRatio;
    },
    calculateInterest(selectedPackagePrice, totalPriceWithInterestRate) {
      return totalPriceWithInterestRate - selectedPackagePrice;
    },
    pushInstallmentsToOfferForm(installment_id) {
      let installment = this.createdPlan.find(
        (installment) => installment.id === installment_id
      );
      this.newTotalPrice = installment.totalPrice;
      installment = {
        ...installment,
        interestRatio: this.interestRates[installment_id],
        interestApplied: this.interestApplied,
        installmentStartDate:
          this.installmentStartDate ??
          new Date(Date.now()).toISOString().substring(0, 10),
        remainingInstallmentStartDate:
          this.remainingInstallmentStartDate ??
          this.createRemainingInstallmentStartDate(),
      };
      this.$emit("update:paymentPlan", installment);
    },
    getInterestRates() {
      return new Promise((resolve, reject) => {
        this.$apiService
          .get("interests")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    createRemainingInstallmentStartDate() {
      const first_date =
        this.startDate ?? new Date(Date.now()).toISOString().substring(0, 10);
      const date = new Date(first_date);
      const result = date.setMonth(date.getMonth() + 1);
      return new Date(result).toISOString().substring(0, 10);
    },
  },
  computed: {
    createdPlan() {
      const plan = [];
      for (let i = 0; i < this.selectedInstallmentChoice; i++) {
        let price = this.calculateInstallmentPrice(i + 1);
        plan.push({
          id: i + 1,
          price: price,
          totalPrice: this.calculateTotalPriceWithInterestRate(i + 1, price),
          interest: this.calculateInterest(
            this.totalPrice,
            this.calculateTotalPriceWithInterestRate(i + 1)
          ),
        });
      }
      return plan;
    },
    tableHeight() {
      switch (this.selectedInstallmentChoice) {
        case 12:
          return 550;
        case 18:
          return 770;
        case 24:
          return 1000;
        default:
          return 550;
      }
    },
  },
  watch: {
    selectedInstallment(value) {
      switch (value) {
        case value <= 12:
          break;
        case value <= 18 && value > 12:
          this.selectedInstallmentChoice = 18;
          break;
        case value <= 24 && value > 18:
          this.selectedInstallmentChoice = 24;
          break;
        default:
          break;
      }
      this.pushInstallmentsToOfferForm(value);
    },
    interestApplied() {
      if (this.selectedInstallment) {
        this.pushInstallmentsToOfferForm(this.selectedInstallment);
      }
    },
  },
});
</script>

<style>
.installment-table__table-foot {
  display: flex;
  align-items: center;
  padding-top: 12px;
  font-size: 15px;
  width: 100%;
}
</style>
