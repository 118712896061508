<template>
  <v-card flat class="pa-5">
    <v-data-table
      :loading="loading"
      :headers="offerHeaders"
      :items="mappedUserOffers"
    >
      <template #top>
        <div class="d-flex justify-content-between align-items-center">
          <h2 v-if="$route.name !== 'lead-show'">Teklifler</h2>
          <div class="d-flex align-items-center">
            <!-- <OfferForm :userId="user_id">
              <v-btn elevation="0" color="primary" v-bind="attrs" v-on="on">
                <v-icon small>mdi-plus</v-icon>
                Yeni Teklif
              </v-btn>
            </OfferForm> -->
          </div>
        </div>
      </template>
      <template #item.actions="{ item }">
        <component
          :offerId="activeOfferId"
          :is="activeOfferId === item.id ? 'OfferForm' : 'div'"
          @close="activeOfferId = null"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2 cursor-pointer"
                color="warning"
                v-bind="attrs"
                v-on="on"
                @click="activeOfferId = item.id"
              >
                mdi-eye
              </v-icon>
            </template>
            Görüntüle
          </v-tooltip>
        </component>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defineComponent } from "vue";
import { FETCH_OFFERS } from "@/core/services/store/offers.module";

import OfferForm from "@/view/content/offers/OfferForm.vue";
import { formatPrice } from "@/core/helper.js";

export default defineComponent({
  name: "OfferList",
  components: { OfferForm },
  props: {
    user_id: Number,
  },
  data() {
    return {
      loading: true,
      offerHeaders: [
        { value: "created_at", text: "Oluşturulma Tarihi", align: "start" },
        { value: "production_name", text: "Ürün Adı", align: "start" },
        { value: "payment_method", text: "Ödeme Yöntemi", align: "start" },
        {
          value: "total_discounted_price",
          text: "Toplam Fiyat",
          align: "start",
        },
        { value: "agent", text: "Agent", align: "start" },
        { value: "status", text: "Teklif Durumu", align: "start" },
        { value: "start_date", text: "Ödeme Başlangıç Tarihi", align: "start" },
        { value: "actions", text: "", width: "100px", align: "start" },
      ],
      activeOfferId: null,
    };
  },
  beforeMount() {
    this.fetchOffers({ user_id: this.user_id })
      .then((reponse) => {
        this.offers = reponse.data;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions([FETCH_OFFERS]),
    formatPrice,
  },
  computed: {
    ...mapGetters(["offers"]),
    mappedUserOffers() {
      return Object.values(this.offers).map((o) => {
        return {
          id: o.id,
          production_name: o.production.name,
          total_discounted_price: formatPrice(
            o.educations.reduce(
              (total, education) =>
                total +
                (parseFloat(education.pivot.new_discounted_price) ||
                  parseFloat(education.pivot.discounted_price)),
              0
            )
          ),
          payment_method: o.payment_method_name,
          agent: o.agent.name,
          status: o.offer_status_name,
          start_date:
            new Date(
              o?.payment_plan?.first_installment_date
            ).toLocaleDateString() || "Reddedildi",
          created_at: this.$moment(o.created_at).format("DD/MM/YYYY HH:mm:ss"),
        };
      });
    },
  },
});
</script>

<style></style>
