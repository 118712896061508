<template>
  <div>
    <v-simple-table class="border mb-5">
      <template v-slot:default>
        <thead id="summaryHead">
          <tr>
            <th class="text-left">Taksit Sayısı</th>
            <th class="v-data-table__divider"></th>
            <th class="text-left">Taksit Tutarı</th>
            <th class="v-data-table__divider"></th>
            <th class="text-left">Toplam İndirim</th>
            <th class="v-data-table__divider"></th>
            <th class="text-left">Toplam Tutar</th>
            <th class="v-data-table__divider"></th>
            <th class="text-left">1.Taksit Başlangıç Tarihi</th>
            <th class="v-data-table__divider"></th>
            <th class="text-left">2.Taksit Başlangıç Tarihi</th>
          </tr>
        </thead>
        <tbody>
          <tr id="summaryBody">
            <td class="text-left">{{ createdPlan.id }}</td>
            <td class="v-data-table__divider"></td>
            <td class="text-left">
              {{ formatPrice(createdPlan.price) }}
            </td>
            <td class="v-data-table__divider"></td>
            <td class="text-left">{{ totalDiscount }}</td>
            <td class="v-data-table__divider"></td>
            <td class="text-left">
              {{ formatPrice(createdPlan.totalPrice) }}
            </td>
            <td class="v-data-table__divider"></td>
            <td class="text-left">
              <v-menu
                v-model="firstDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="firstFormattedDate"
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="createdPlan.installmentStartDate"
                  class="p-0"
                  no-title
                  @input="firstDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </td>
            <td class="v-data-table__divider"></td>
            <td class="text-left">
              <v-menu
                v-model="remainingDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="remainingFormattedDate"
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="createdPlan.remainingInstallmentStartDate"
                  class="p-0"
                  no-title
                  @input="remainingDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <PaymentsTable
      :createdPlan="createdPlan"
      :installmentStartdate="createdPlan.startDate"
    />
  </div>
</template>

<script>
import PaymentsTable from "./PaymentsTable.vue";
import { formatPrice } from "@/core/helper.js";

export default {
  components: {
    PaymentsTable,
  },
  props: {
    createdPlan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      firstDateMenu: false,
      remainingDateMenu: false,
    };
  },
  computed: {
    totalDiscount() {
      const total = this.createdPlan?.educations.reduce((acc, curr) => {
        return acc + (curr.price - curr.newPrice);
      }, 0);
      return formatPrice(total);
    },
    firstFormattedDate() {
      return this.formatDate(this.createdPlan.installmentStartDate);
    },
    remainingFormattedDate() {
      return this.formatDate(this.createdPlan.remainingInstallmentStartDate);
    },
  },
  methods: {
    formatPrice,
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    createRemainingInstallmentStartDate(startDate = null) {
      const first_date =
        startDate ?? new Date(Date.now()).toISOString().substring(0, 10);
      const date = new Date(first_date);
      const result = date.setMonth(date.getMonth() + 1);
      return new Date(result).toISOString().substring(0, 10);
    },
  },
  watch: {
    "createdPlan.installmentStartDate"(val) {
      this.createdPlan.remainingInstallmentStartDate =
        this.createRemainingInstallmentStartDate(val);
      this.$root.$emit("update:startDate", val);
    },
    "createdPlan.remainingInstallmentStartDate"(val) {
      this.$root.$emit("update:remainingDate", val);
    },
  },
};
</script>

<style lang="scss">
.v-text-field__details {
  display: none;
}
.v-text-field__slot {
  width: 25px;
}
.v-picker {
  height: 21rem;
  right: 1rem;
}
</style>
