<template>
  <v-card>
    <v-card-text>
      <div v-if="logs.length > 0">
        <v-data-table
          :headers="headers"
          :items="logs"
          :items-per-page="10"
          :loading="loading"
          hide-default-footer
          @page-count="pagination.current_page = $event"
        >
          <template #item.created_at="{ item }">
            {{ returnLocaleDate(item.created_at) }}
          </template>
          <template #item.causer="{ item }">
            <router-link
              v-if="item?.causer?.id"
              :to="{
                name: 'user-edit',
                params: { id: item?.causer?.id },
              }"
            >
              {{ item.causer.name }}
            </router-link>
            <span v-else>{{ item.causer }}</span>
          </template>
          <template #item.subject="{ item }">
            <router-link
              v-if="item?.subject?.id"
              :to="{
                name: `${item.log_name}-show`,
                params: { id: item?.subject?.id },
              }"
            >
              {{ item.subject?.name }}
            </router-link>
          </template>
        </v-data-table>
        <v-pagination
          v-model="pagination.current_page"
          :length="pagination.last_page"
          :total-visible="pagination.per_page"
          :server-items-length="pagination.total"
          :pagination.sync="pagination"
          @input="$emit('get-logs', pagination.current_page)"
        ></v-pagination>
      </div>
      <div
        v-else
        style="height: 250px"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <h3 class="mt-5">Kayıt Bulunamadı</h3>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
moment.locale("tr");

export default {
  props: {
    logs: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Object,
      default: () => ({
        last_page: 0,
        per_page: 0,
        current_page: 1,
        total: 0,
      }),
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.showDescription) {
      this.headers.push({
        text: "Açıklama",
        value: "description",
        align: "left",
      });
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Tarih",
          value: "created_at",
          align: "left",
        },
        {
          text: "Kullanıcı",
          value: "causer",
          align: "left",
        },
        {
          text: "Event",
          value: "event",
          align: "left",
        },
        {
          text: "Kullanıcı Rolü",
          value: "causer.roles[0].description",
          align: "left",
        },
        {
          text: "İşlem",
          value: "subject",
          align: "left",
        },
        {
          text: "Eski Değer",
          value: "properties.newValue",
          align: "left",
        },
        {
          text: "Yeni Değer",
          value: "properties.oldValue",
          align: "left",
        },
      ],
    };
  },
  methods: {
    returnLocaleDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  watch: {
    showDescription(val) {
      if (val) {
        this.headers.push({
          text: "Açıklama",
          value: "description",
          align: "left",
        });
      }
    },
  },
};
</script>
