var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[(_vm.logs.length > 0)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.logs,"items-per-page":10,"loading":_vm.loading,"hide-default-footer":""},on:{"page-count":function($event){_vm.pagination.current_page = $event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.returnLocaleDate(item.created_at))+" ")]}},{key:"item.causer",fn:function({ item }){return [(item?.causer?.id)?_c('router-link',{attrs:{"to":{
              name: 'user-edit',
              params: { id: item?.causer?.id },
            }}},[_vm._v(" "+_vm._s(item.causer.name)+" ")]):_c('span',[_vm._v(_vm._s(item.causer))])]}},{key:"item.subject",fn:function({ item }){return [(item?.subject?.id)?_c('router-link',{attrs:{"to":{
              name: `${item.log_name}-show`,
              params: { id: item?.subject?.id },
            }}},[_vm._v(" "+_vm._s(item.subject?.name)+" ")]):_vm._e()]}}],null,false,336530817)}),_c('v-pagination',{attrs:{"length":_vm.pagination.last_page,"total-visible":_vm.pagination.per_page,"server-items-length":_vm.pagination.total,"pagination":_vm.pagination},on:{"update:pagination":function($event){_vm.pagination=$event},"input":function($event){return _vm.$emit('get-logs', _vm.pagination.current_page)}},model:{value:(_vm.pagination.current_page),callback:function ($$v) {_vm.$set(_vm.pagination, "current_page", $$v)},expression:"pagination.current_page"}})],1):_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center",staticStyle:{"height":"250px"}},[_c('h3',{staticClass:"mt-5"},[_vm._v("Kayıt Bulunamadı")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }