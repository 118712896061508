<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="12">
        <PackageCards
          @update:selectedPackage="handlePackage"
          :selectedPackage="selectedPackage"
        />
      </v-col>
    </v-row>
    <v-row dense v-if="selectedPackage">
      <v-col cols="12" md="4">
        <v-text-field
          readonly
          append-icon="mdi-currency-try"
          hide-details="auto"
          v-model="selectedPackageBasePrice"
          label="Ürün Fiyatı"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          append-icon="mdi-currency-try"
          hide-details="auto"
          @focus="priceChangeFromPackageSelector = true"
          @blur="priceChangeFromPackageSelector = false"
          v-model.number="selectedPackage.discounted_price"
          @input="handlePackagePriceChange($event)"
          label="İndirimli Fiyat"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          append-icon="mdi-percent"
          hide-details="auto"
          :value="discountedPercent"
          @input="handlePackagePercentChange($event)"
          label="İndirim Oranı"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import PackageCards from "@/view/content/components/packages/PackageCards.vue";

export default defineComponent({
  emits: ["update:selectedPackage"],
  components: {
    PackageCards,
  },
  props: {
    selectedPackage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      priceChangeFromPackageSelector: false,
    };
  },
  mounted() {
    //Total price değiştiğinde discountedPercent ve discountedPrice değişiyor.
    this.$root.$on("update:totalPrice", (discountedPrice) => {
      if (!this.priceChangeFromPackageSelector)
        this.selectedPackage.discounted_price = discountedPrice;
    });
  },
  methods: {
    handlePackage(selectedPackage) {
      const totalBasePrice = selectedPackage.educations.reduce(
        (total, training) => total + training.price,
        0
      );
      const discountedPercentForPackage =
        ((totalBasePrice - selectedPackage.discounted_price) / totalBasePrice) *
        100;
      const educations = selectedPackage.educations.map((training) => {
        const discountPricePerTraining = Math.round(
          training.price - (training.price * discountedPercentForPackage) / 100
        );
        return {
          ...training,
          discounted_price: discountPricePerTraining,
        };
      });

      this.$emit("update:selectedPackage", {
        ...selectedPackage,
        educations,
      });
    },
    handlePackagePriceChange(discountedPrice, discounted_percent = null) {
      if (typeof discountedPrice === "string")
        discountedPrice = parseFloat(discountedPrice);
      if (!discounted_percent) {
        discounted_percent =
          ((this.selectedPackageBasePrice - discountedPrice) /
            this.selectedPackageBasePrice) *
          100;
      }
      const educations = this.selectedPackage.educations.map((training) => {
        const discountPricePerTraining = Math.round(
          training.price - (training.price * discounted_percent) / 100
        );
        return {
          ...training,
          discounted_price: discountPricePerTraining,
        };
      });

      this.$emit("update:selectedPackage", {
        ...this.selectedPackage,
        educations,
      });
      this.$root.$emit("forceRender", true);
    },
    handlePackagePercentChange(e) {
      const discountedPrice =
        this.selectedPackageBasePrice -
        (this.selectedPackageBasePrice * e) / 100;
      this.handlePackagePriceChange(discountedPrice, e);
    },
  },
  computed: {
    discountedPercent() {
      if (this.selectedPackage.discounted_price === null) return null;
      else if (
        this.selectedPackage.discounted_price === this.selectedPackageBasePrice
      )
        return null;
      else
        return Math.round(
          ((this.selectedPackageBasePrice -
            this.selectedPackage.discounted_price) /
            this.selectedPackageBasePrice) *
            100
        );
    },
    selectedPackageBasePrice() {
      return this.selectedPackage.educations.reduce(
        (total, education) => total + education.price,
        0
      );
    },
  },
});
</script>

<style></style>
