<template>
  <v-card flat class="pa-5">
    <div class="d-flex justify-content-between">
      <h2 v-if="!fromShowLeadPage">Notlar ve Kayıtlar</h2>
      <v-spacer></v-spacer>
      <div class="d-flex align-items-center">
        <v-icon
          color="error cursor-pointer"
          class="mr-2"
          :disabled="!hasNote"
          @click="showDeleteNoteModal"
        >
          mdi-delete
        </v-icon>
        <v-text-field
          v-model="search"
          label="Ara"
          class="mx-4 mt-0 pt-0"
          hide-details="auto"
          single-line
          append-icon="search"
        ></v-text-field>
      </div>
    </div>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="notes"
        :search="search"
        :header-props="{ sortIcon: null }"
        v-model="selectedNote"
        :loading="loading"
        :items-per-page="20"
        dense
        show-select
        single-select
      >
        <template #item.note_type="{ item }">
          <span> {{ convertNoteType(item.note_type) }}</span>
        </template>
        <template #item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <Note
                :noteable_type="noteable_type"
                :note_type="item.note_type"
                :name="leadName"
                :noteable_id="noteable_id || Number($route.params.id)"
                :note_id="item.id"
                :showListOfNotes="false"
                :clearData="false"
              >
                <v-icon
                  class="mr-2 cursor-pointer"
                  color="warning"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </Note>
            </template>
            <span>Düzenle</span>
          </v-tooltip>
        </template>
        <template v-slot:item.call_result_id="{ item }">
          <span v-if="item.call_result_id">{{ item.call_result.text }}</span>
        </template>
        <template v-slot:item.sub_call_result_id="{ item }">
          <span v-if="item.sub_call_result_id">{{
            item.sub_call_result.text
          }}</span>
        </template>
        <template v-slot:item.content="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="item.content.length > 30 ? on : {}">{{
                item.content.length > 30
                  ? item.content.substring(0, 30) + "..."
                  : item.content
              }}</span>
            </template>
            <span>{{ item.content }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.document_id="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="item.document_id ? 'primary' : 'grey lighten-2'"
                @click="showDocumentDialog(item.document)"
                v-on="on"
                v-bind="attrs"
              >
                mdi-file-document
              </v-icon>
            </template>
            <span v-if="item.document_id">Dokümanı Görüntüle</span>
            <span v-else>Doküman Yok</span>
          </v-tooltip>
        </template>
        <template v-slot:item.created_at="{ item }">
          <span>{{ returnLocaleDate(item.created_at) }}</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { DELETE_NOTE, FETCH_NOTES } from "@/core/services/store/notes.module";
import { defineComponent } from "vue";
import DocumentService from "@/core/services/api/documents.service";
import Note from "./Note.vue";

export default defineComponent({
  components: {
    Note,
  },
  props: {
    noteable_id: {
      type: Number,
      required: true,
    },
    noteable_type: {
      type: String,
      required: true,
    },
    fromShowLeadPage: {
      type: Boolean,
      default: false,
    },
    leadName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      headers: [
        { text: "Tarih", value: "created_at", align: "start" },
        { text: "Tip", value: "note_type", align: "start" },
        { text: "Açıklama", value: "content", align: "start" },
        { text: "Sonuç", value: "call_result_id", align: "start" },
        {
          text: "Alt Sonuç",
          value: "sub_call_result_id",
          align: "start",
        },
        { text: "Doküman", value: "document_id", align: "start" },
        {
          value: "actions",
          align: "start",
          sortable: false,
        },
        { text: "", value: "data-table-select", align: "left" },
      ],
      search: "",
      selectedNote: [],
      dialog: false,
      loading: true,
    };
  },
  mounted() {
    this.getLeadNotes();
  },
  methods: {
    ...mapActions([DELETE_NOTE, FETCH_NOTES]),
    convertNoteType(type) {
      return type === "call_record" ? "Görüşme Kaydı" : "Not";
    },
    async acceptDelete() {
      try {
        await this.deleteNote(this.selectedNote[0]);
        this.$toast.success("Not silindi", {
          position: "bottom-right",
          duration: 2000,
        });
        this.selectedNote = [];
      } catch (error) {
        this.$toast.error("Not silinirken bir hata oluştu", {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    getLeadNotes() {
      this.fetchNotes({
        noteable_type: this.noteable_type,
        noteable_id: this.noteable_id,
      })
        .then((response) => {
          this.notes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showDeleteNoteModal() {
      const params = {
        title: "Seçtiğiniz not silinecek",
        text: "Seçili notu silmek istediğinize emin misiniz?",
        onConfirm: () => {
          return this.acceptDelete();
        },
      };
      this.$approvalModal.show(params);
    },
    showDocumentDialog(doc) {
      if (!doc) return;
      const path = DocumentService.createPreviewLinkOfDocument(doc);
      const params = {
        fileURL: path,
        fileName: doc.name,
        mimeType: doc.mime_type,
      };
      this.$documentReader.show(params);
    },
    returnLocaleDate(date) {
      return this.$moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  computed: {
    ...mapGetters(["notes"]),
    hasNote() {
      return this.selectedNote.length === 1;
    },
  },
});
</script>

<style></style>
