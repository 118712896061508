<template>
  <v-row dense>
    <v-col cols="12" md="12">
      <v-chip-group
        active-class="primary"
        column
        v-model="preferred_class"
        @change="$emit('update:preferred_class', $event)"
      >
        <v-chip v-for="c in classes" :value="c.value" :key="c.value">
          {{ c.translate }}
        </v-chip>
      </v-chip-group>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from "vue";
import AxiosInstance from "@/core/services/axios.service";

export default defineComponent({
  name: "ClassToOpen",
  props: {
    preferred_class: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      classes: [],
    };
  },
  mounted() {
    AxiosInstance.get("/enums", {
      params: {
        enum: "preferred_class_enum",
      },
    }).then((response) => {
      this.classes = response.data;
    });
  },
});
</script>
