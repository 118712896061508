<template>
  <v-dialog :disabled="disabled" persistent v-model="dialog" fullscreen>
    <template #activator="{ on, attrs }">
      <div class="display-inline" v-bind="attrs" v-on="on">
        <slot />
      </div>
    </template>
    <Card v-if="dialog" :title="title" modal>
      <template #close>
        <v-btn icon @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <v-container>
        <v-row v-if="!loading">
          <v-col cols="12" md="5">
            <Stepper title="Ürün Seçimi" step="1">
              <PackageSelector
                @update:selectedPackage="handlePackageChange"
                :selectedPackage="offerForm.selectedPackage"
              />
            </Stepper>
            <Stepper
              title="İndirim Oranları"
              step="2"
              v-if="offerForm.selectedPackage"
            >
              <TrainingsTable
                :contents="offerForm.selectedPackage.educations"
                :selectedPackageId="offerForm.selectedPackage.id"
                @update:contents="handlePackageContentChange"
              />
            </Stepper>
            <Stepper
              title="Ödeme Yöntemi"
              v-if="offerForm.selectedPackage"
              step="3"
            >
              <PaymentMethods
                :payment_method="offerForm.payment_method"
                @update:payment_method="handlePaymentMethodChange"
              />
            </Stepper>
            <Stepper
              title="Başvuranın Seçeceği 1. Aşama Sınıfı"
              v-if="isGoldenMadeChoiceAvailable"
              step="4"
            >
              <ClassToOpen
                :preferred_class="offerForm.preferred_class"
                @update:preferred_class="handleStudentClassChange"
              />
            </Stepper>
            <Stepper
              title="Başvuranın Seçeceği 3. Aşama Sınıfı"
              v-if="isSiliconmadeChoiceAvailable"
              :step="isGoldenMadeChoiceAvailable ? 5 : 4"
            >
              <ThirdBaseClass
                :third_base_class_choice="offerForm.third_base_class_choice"
                @update:third_base_class_choice="
                  (value) => {
                    offerForm.third_base_class_choice = value;
                  }
                "
              />
            </Stepper>
          </v-col>
          <v-col
            cols="12"
            md="6"
            offset-lg="1"
            v-if="offerForm.payment_method && offerForm.selectedPackage"
          >
            <Stepper
              title="Taksit Tablosu"
              :step="isSiliconmadeChoiceAvailable ? 6 : 5"
              v-if="offerForm.payment_method === 2"
            >
              <Installments
                @update:paymentPlan="handlePaymentPlanChange"
                :remainingInstallmentStartDate="
                  offerForm.paymentPlan.remainingInstallmentStartDate
                "
                :installmentStartDate="
                  offerForm.paymentPlan.installmentStartDate
                "
                :cancelInterestRate="offerForm.paymentPlan.cancelInterestRate"
                :installmentCount="offerForm.paymentPlan.installmentCount"
                :totalPrice="
                  offerForm.totalDiscountedPrice ??
                  offerForm.selectedPackage.discounted_price
                "
              />
            </Stepper>
            <Stepper
              title="Teklif Özeti"
              :step="isSiliconmadeChoiceAvailable ? 7 : 6"
              v-if="
                offerForm.paymentPlan.installmentCount &&
                offerForm.payment_method === 2
              "
            >
              <CalculatedLastPrices
                v-if="offerForm.installments"
                :educations="offerForm.selectedPackage.educations"
                :remainingInstallmentStartDate="
                  offerForm.paymentPlan.remainingInstallmentStartDate
                "
                :installmentStartDate="
                  offerForm.paymentPlan.installmentStartDate
                "
                :installments="offerForm.installments"
                @update:createdPlan="handleLastPricesChange"
              ></CalculatedLastPrices>
            </Stepper>
            <v-spacer />
            <v-row>
              <v-col
                cols="12"
                md="12"
                v-if="offerForm.payment_method"
                class="d-flex justify-content-end"
              >
                <v-btn
                  :disabled="!submitDisabled"
                  @click="closeForm"
                  class="mr-5"
                >
                  İptal
                </v-btn>
                <v-btn
                  :loading="submitLoading"
                  :disabled="!submitDisabled || !hasEmail"
                  color="primary"
                  v-if="!offerId"
                  @click="submitForm"
                >
                  Teklifi Kaydet ve Gönder
                </v-btn>
                <v-btn
                  :loading="submitLoading"
                  :disabled="!isAvailableForDelete"
                  color="error"
                  v-role="{
                    roles: ['lead_manager', 'superadmin', 'siliconmade_admin'],
                  }"
                  v-if="offerId"
                  @click="showDeleteOfferModal"
                >
                  Teklifi İptal Et ve Sil
                </v-btn>
              </v-col>
              <v-row
                v-if="!hasEmail"
                align="center"
                class="justify-content-end"
              >
                <v-col cols="auto">
                  <v-alert
                    type="warning"
                    border="left"
                    color="yellow darken-3"
                    elevation="2"
                    class="pa-1 mb-0"
                  >
                    <span class="font-size-sm"
                      >Teklif oluşturmadan önce kullanıcının e-posta adresini
                      güncellemelisiniz!</span
                    >
                  </v-alert>
                </v-col>
                <v-col cols="auto" class="mr-3">
                  <router-link
                    :to="{ name: 'user-edit', params: { id: lead.user.id } }"
                  >
                    <v-btn color="primary" class="ml-2 pa-1">
                      Kullanıcıya Git
                    </v-btn>
                  </router-link>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
        </v-row>
        <div
          v-else
          style="height: 250px"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            :size="70"
            width="7"
          ></v-progress-circular>
          <h3 class="mt-5">Lütfen bekleyiniz...</h3>
        </div>
      </v-container>
    </Card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  GET_OFFER,
  FETCH_OFFERS,
  CREATE_OFFER,
  UPDATE_OFFER,
  DELETE_OFFER,
} from "@/core/services/store/offers.module";

import TrainingsTable from "./TrainingsTable.vue";
import PaymentMethods from "./PaymentMethods.vue";
import PackageSelector from "./PackageSelector.vue";
import Installments from "./Installments.vue";
import Stepper from "./Stepper.vue";
import ClassToOpen from "./ClassToOpen.vue";
import CalculatedLastPrices from "./CalculatedLastPrices.vue";
import ThirdBaseClass from "./ThirdBaseClass.vue";

import Card from "@/view/content/components/Card.vue";

export default defineComponent({
  name: "OfferForm",
  data() {
    return {
      dialog: false,
      offerForm: {
        selectedPackage: null,
        totalDiscountedPrice: null,
        payment_method: null,
        paymentPlan: {
          installmentCount: null,
          installmentStartDate: null,
          remainingInstallmentStartDate: null,
          cancelInterestRate: true,
          interestRatio: null,
        },
        installments: [],
        preferred_class: null,
        third_base_class_choice: null,
        offer_status: null,
        offer_status_name: null,
      },
      loading: !!this.offerId,
      loader: null,
      submitLoading: false,
      submitDisabled: true,
      hasEmail: false,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    offerId: Number,
    lead_id: {
      type: Number,
      required: true,
    },
    lead: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (this.offerId) this.dialog = true;
    if (this.offerId) {
      this.getOffer(this.offerId)
        .then((res) => {
          this.offerForm = {
            selectedPackage: {
              ...res.production,
              educations: this.prepareEducations(res.educations),
              discounted_price: this.prepareSelectedPackageDiscountedPrice(
                this.prepareEducations(res.educations)
              ),
            },
            payment_method: res.payment_method,
            paymentPlan: {
              installmentCount: res.payment_plan.installment_count,
              installmentStartDate: new Date(
                res.payment_plan.first_installment_date
              )
                .toISOString()
                .substring(0, 10),
              remainingInstallmentStartDate: new Date(
                res.payment_plan.installment_start_date
              )
                .toISOString()
                .substring(0, 10),
              cancelInterestRate:
                res.payment_plan.interest_ratio !== null ? false : true,
            },
            preferred_class: res.preferred_class,
            offer_status: res.offer_status,
            offer_status_name: res.offer_status_name,
            third_base_class_choice: res.third_base_class_choice,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.$root.$on("update:totalPrice", (totalPrice) => {
      this.offerForm.totalDiscountedPrice = totalPrice;
    });
    this.$root.$on("update:startDate", (startDate) => {
      if (!this.offerId) {
        this.offerForm.paymentPlan.installmentStartDate = startDate;
        this.offerForm.installments.startDate = startDate;
      }
    });
    this.$root.$on("update:remainingDate", (remaining) => {
      if (!this.offerId) {
        this.offerForm.paymentPlan.remainingInstallmentStartDate = remaining;
        this.offerForm.installments.remainingStartDate = remaining;
      }
    });
  },
  methods: {
    ...mapActions([
      GET_OFFER,
      CREATE_OFFER,
      UPDATE_OFFER,
      FETCH_OFFERS,
      DELETE_OFFER,
    ]),
    handlePackageChange(pack) {
      this.offerForm.selectedPackage = pack;
    },
    handlePackageContentChange(contents) {
      this.offerForm.selectedPackage.educations = contents;
    },
    handlePaymentMethodChange(method) {
      this.offerForm.payment_method = method;
    },
    handlePaymentPlanChange(installments) {
      this.offerForm.installments = installments;
      this.offerForm.paymentPlan = {
        installmentCount: installments.id,
        installmentAmount: installments.price,
        interestRatio: installments.interestRatio,
        cancelInterestRate: !installments.interestApplied,
        installmentStartDate: installments.installmentStartDate,
        remainingInstallmentStartDate:
          installments.remainingInstallmentStartDate,
      };
    },
    handleStudentClassChange(selectedClass) {
      this.offerForm.preferred_class = selectedClass;
    },
    handleLastPricesChange(lastPrices) {
      this.offerForm.selectedPackage.educations.forEach((education) => {
        education.new_discounted_price = lastPrices.educations.find(
          (ed) => ed.id === education.id
        ).newPrice;
      });
    },
    closeForm() {
      this.offerForm = {
        selectedPackage: null,
        totalDiscountedPrice: null,
        payment_method: null,
        paymentPlan: {
          installmentCount: null,
          interestRatio: null,
          installmentStartDate: null,
          remainingInstallmentStartDate: null,
          cancelInterestRate: true,
        },
        preferred_class: null,
        third_base_class_choice: null,
        offer_status: null,
        offer_status_name: null,
      };
      this.$emit("close");
      this[this.loader] = false;
      this.loader = null;
      this.submitDisabled = true;
      this.dialog = false;
    },
    async submitForm() {
      this.loader = "submitLoading";
      this.submitDisabled = false;
      this.stopExecution = false;
      const formData = {
        ...this.offerForm,
        fromLeadShowPage: this.$route.name === "lead-show" ? true : false,
      };
      if (this.offerId) formData.id = this.offerId;
      if (this.lead_id) formData.lead_id = this.lead_id;
      if (this.offerId) {
        try {
          await this.updateOffer(formData);
          this.$toast.success("Teklif Güncellendi", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this[this.loader] = false;
          this.loader = null;
          this.submitDisabled = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
            dismissible: true,
          });
        }
      } else {
        try {
          await this.createOffer(formData);
          this.$toast.success("Teklif Oluşturuldu", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this[this.loader] = false;
          this.loader = null;
          this.submitDisabled = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
            dismissible: true,
          });
        }
      }
      if (this.stopExecution) return;
      this.closeForm();
    },
    async offerDelete() {
      this.loader = "submitLoading";
      this.submitDisabled = false;
      this.stopExecution = false;
      try {
        await this.deleteOffer({ id: this.offerId });
        this.$toast.success("Teklif İptal Edildi", {
          position: "bottom-right",
          duration: 2000,
        });
      } catch (error) {
        this.stopExecution = true;
        this[this.loader] = false;
        this.loader = null;
        this.submitDisabled = true;
        this.$toast.error(error[0].detail, {
          position: "bottom-right",
          duration: 2000,
          dismissible: true,
        });
      }
      if (this.stopExecution) return;
      this.closeForm();
    },
    prepareEducations(educations) {
      const contents = [];
      educations.forEach((education) => {
        const temp = {};
        temp.id = education.id;
        temp.price = education.price;
        temp.name = education.name;
        temp.discounted_price = parseFloat(education.pivot.discounted_price);

        contents.push(temp);
      });
      return contents;
    },
    prepareSelectedPackageDiscountedPrice(educations) {
      return educations.reduce((a, b) => a + b.discounted_price, 0);
    },
    showDeleteOfferModal() {
      const params = {
        title: "Teklif Silinecek",
        text: `'${this.offerForm.offer_status_name}' durumunda ki teklif silinecektir. Buna bağlı olarak kullanıcıya tanımlanmış ödeme planları, mesafeli satış sözleşmesi ve ön bilgilendirme formları da silinecektir. Onaylıyor musunuz?`,
        onConfirm: () => {
          return this.offerDelete();
        },
      };
      this.$approvalModal.show(params);
    },
  },
  computed: {
    ...mapGetters(["offer"]),
    title() {
      return this.offerId ? "Teklif Görüntüle" : "Yeni Teklif Oluştur";
    },
    isAvailableForDelete() {
      const notAvailable = ["draft"];
      return !notAvailable.includes(this.offerForm.offer_status);
    },
    isGoldenMadeChoiceAvailable() {
      const isGoldenmadeAvailable =
        this.offerForm?.selectedPackage?.educations?.some(
          (education) => education.id === 1 || education.id === 5
        );
      return isGoldenmadeAvailable;
    },
    isSiliconmadeChoiceAvailable() {
      const isSiliconmadeAvailable =
        this.offerForm?.selectedPackage?.educations?.some(
          (education) => education.id === 3 || education.id === 15
        );
      return isSiliconmadeAvailable;
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
    },
    "offerForm.payment_method"() {
      if (this.offerId) return;
      this.offerForm.paymentPlan = {
        installmentCount: null,
        installmentStartDate: null,
        cancelInterestRate: true,
        interestRatio: null,
      };
      this.offerForm.installments = null;
    },
    lead: {
      immediate: true,
      handler(val) {
        if (val.user?.email?.email) {
          this.hasEmail = true;
        } else {
          this.hasEmail = false;
        }
      },
    },
  },
  components: {
    Card,
    TrainingsTable,
    PaymentMethods,
    PackageSelector,
    Stepper,
    Installments,
    ClassToOpen,
    CalculatedLastPrices,
    ThirdBaseClass,
  },
});
</script>

<style></style>
